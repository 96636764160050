<template>
	<div>
		<div class="row">
			<div class="col-lg-6">
				<div class="panel">
				    <div class="panel-body">
				        <h3 class="panel-title"><img :src="require('@/assets/images/icons/info.svg')" class="panel-icon" alt=""> Thông tin nạp tiền</h3>

				        <div class="row" v-if="paymentData.logo || paymentData.info">
				        	<div class="col-lg-12">
				        		<div class="payment-info">
				        			<div class="logo pb-3" v-if="paymentData.logo">
				        				<img :src="paymentData.logo" alt="">
				        			</div>
				        			<div class="content" v-html="paymentData.info"></div>
				        		</div>
				        	</div>
				        </div>

				        <form action="#" @submit.prevent="addPayment">
				        	<div class="form-group">
				        		<label>Logo Ảnh ( Nếu có ) :</label>
				        		<input type="text" v-model="paymentData.logo" class="form-control" placeholder="Nhập Logo ảnh của ngân hàng">
				        	</div>
				        	<div class="form-group">
				        		<label>Thông tin nạp tiền :</label>
				        		<textarea rows="6" v-model="paymentData.info" class="form-control" placeholder="Nhập thông tin nạp tiền ví dụ như : số tài khoản, chủ tài khoản, ngân hàng,...." required=""></textarea>
				        	</div>
				        	<div class="alert b-orange">
			        			Sử dụng copy{NỘI DUNG ĐỂ KHÁCH COPY} <br>
			        			Ví dụ : copy{<span>{{accountBank}}</span>} sẽ thành <a class="au font-weight-bold cursor-pointer" @click="copyTest"> {{ accountBank }} <i class="fa fa-copy"></i></a>
			        		</div>
				        	<button class="btn b-info btn-block"> Thêm thông tin</button>
				        </form>
				    </div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="panel">
				    <div class="panel-body">
				        <h3 class="panel-title"><img :src="require('@/assets/images/icons/settings.svg')" class="panel-icon" alt=""> Cài đặt nạp tiên</h3>
				        <form action="#" @submit.prevent="settingsPayment">
				        	<div class="form-group">
				        		<label>Hướng dẫn nạp tiền :</label>
				        		<router-link class="btn b-success btn-block" to="/admin/notification">Đi tới cài đặt thông báo hướng dẫn <br> ( Chọn tại phần nạp tiền ngân hàng )</router-link>
				        	</div>

				        	<div class="form-group">
				        		<label>Nội dung nạp tiền :</label>
				        		<input type="text" v-model="settings.content_payment" class="form-control" placeholder="Ví dụ : {username} nap tien sẽ là admin nap tien">
				        	</div>
				        	<div class="alert b-danger">
				        		Sử dụng {username} sẽ là tên của khách hàng <br>
				        		Ví dụ : {username} nap tien sẽ thành {{ user.username }} nap tien</a>
				        	</div>
				        	<button class="btn b-info btn-block">Cập nhật</button>
				        </form>
				    </div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="panel">
				    <div class="panel-body">
				        <h3 class="panel-title"><img :src="require('@/assets/images/icons/lists.svg')" class="panel-icon" alt=""> Danh sách thanh toán</h3>
				        <div class="table-responsive">
				        	<table class="table table-bordered table-hover">
				        		<thead>
				        			<tr>
				        				<th>#</th>
				        				<th>Thao tác</th>
				        				<th>Logo</th>
				        				<th>Thông tin</th>
				        				<th>Trạng thái</th>
				        				<th>Thời gian</th>
				        			</tr>
				        		</thead>
				        		<tbody>
				        			<tr v-for="(pm, k) in paymentLists">
				        				<td>{{ k + 1 }}</td>
				        				<td>
				        					<div class="btn-action">
				        						<button class="btn b-info" @click="getInfoPayment(pm.id)"><i class="fa fa-edit"></i></button>
				        						<button class="btn b-danger" @click="deletePayment(pm)"><i class="fa fa-trash"></i></button>
				        					</div>
				        				</td>
				        				<td>
				        					<img :src="pm.logo" height="50" alt="">
				        				</td>
				        				<td><div class="text-pre-wrap" v-html="pm.info"></div></td>
				        				<td>
				        					<span class="label label-success" v-if="pm.status === 1">Bật</span>
				        					<span class="label label-danger" v-else>Tắt</span>
				        				</td>
				        				<td>
				        					{{ pm.action_time | timeText }}
				        				</td>
				        			</tr>
				        		</tbody>
				        	</table>
				        </div>
				    </div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		    <div class="modal-dialog modal-dialog-centered">
		        <div class="modal-content">
		            <div class="modal-header">
		            	<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
		                <h4 class="modal-title" id="exampleModalCenterTitle">Cập nhật thanh toán</h4>
		            </div>
		            <div class="modal-body">
		            	<div class="row" v-if="infoPayment.logo || infoPayment.info">
				        	<div class="col-lg-12">
				        		<div class="payment-info">
				        			<div class="logo pb-3" v-if="infoPayment.logo">
				        				<img :src="infoPayment.logo" alt="">
				        			</div>
				        			<div class="content" v-html="infoPayment.info"></div>
				        		</div>
				        	</div>
				        </div>
		                <form action="" method="POST" @submit.prevent="updatePayment">
		                	<div class="form-group">
				        		<label>Logo Ảnh ( Nếu có ) :</label>
				        		<input type="text" v-model="infoPayment.logo" class="form-control" placeholder="Nhập Logo ảnh của ngân hàng">
				        	</div>
				        	<div class="form-group">
				        		<label>Thông tin nạp tiền :</label>
				        		<textarea rows="4" v-model="infoPayment.info" class="form-control" placeholder="Nhập thông tin nạp tiền ví dụ như : số tài khoản, chủ tài khoản, ngân hàng,...." required=""></textarea>
				        	</div>
				        	<div class="form-group">
				        		<label>Trạng thái :</label>
				        		<select class="form-control" v-model="infoPayment.status">
				        			<option value="0">Tắt</option>
				        			<option value="1">Bật</option>
				        		</select>
				        	</div>
				        	<button class="btn b-danger btn-block">Cập nhật</button>
		                </form>
		            </div><br>
		        </div>
		    </div>
		</div>
	</div>
</template>

<script>

	import '@/assets/css/payment.css';

	export default {
		data () {
			return {
				infoPayment: {},
				paymentData: {},
				accountBank: '0123456789'
			}
		},
		props: ['payment', 'settings', 'user'],
		computed: {
			paymentLists () {
				return this.payment;
			}
		},
		methods: {
			settingsPayment () {
				this.$http.post('admin/settings', this.settings).then(res => {
					this.$swal('Thông báo', res.body.message, (res.body.status == 1 ? 'success' : 'error'));
				});
			},
			addPayment () {
				this.$http.post('admin/payment?action=add', this.paymentData).then(res => {
					if (res.body.status == 1) {
						this.paymentData = {};
						this.$store.dispatch('siteData');
					}
					this.$swal('Thông báo', res.body.message, (res.body.status == 1 ? 'success' : 'error'));
				});
			},
			updatePayment () {
				this.$http.post('admin/payment?action=update', this.infoPayment).then(res => {
					if (res.body.status == 1) {
						this.$store.dispatch('siteData');
					}
					this.$swal('Thông báo', res.body.message, (res.body.status == 1 ? 'success' : 'error'));
				});
			},
			getInfoPayment (id) {
				this.$http.post('admin/payment?action=data', {id: id}).then(res => {
					if (res.body.data.id) {
						this.infoPayment = res.body.data;
						jQuery(($) => {
							$('#exampleModalCenter').modal('show');
						});
					}
				});
			},
			deletePayment (data) {
				this.$swal({
					title: 'Chắc chắn?',
					html: 'Chắc chắn xóa thông tin thanh toán này ? <br><b class="text-pre-wrap">' + data.info + '</b>',
					imageUrl: require('@/assets/images/icons/info.svg'),
					imageHeight: '100px',
					showCancelButton: 1,
					confirmButtonText: 'Đồng ý'
				}).then((isConfirm) => {
					if (isConfirm.value) {
						this.$http.post('admin/payment?action=delete', {id: data.id}).then(res => {
							if (res.body.status == 1) {
								this.$store.dispatch('siteData');
							}
							this.$swal('Thông báo', res.body.message, (res.body.status == 1 ? 'success' : 'error'));
						});
					}
				});
			},
			copyTest () {
				this.$copyText(this.accountBank).then(() => {
					this.$swal('Thông báo', 'Copy thành công <b>'+ this.accountBank +'</b>', 'success');
				});
			}
		}
	}
</script>